import {
  AgencyConnection,
  AgencyData,
  AgencyDataUpload,
} from "@/types/agencies";
import { MainAlertProps } from "@/types/common/alerts";
import { apiLive } from "../common/api";

export const HandleSaveAgencySettings = async (
  getToken: () => Promise<string | undefined>,
  uploadData: AgencyDataUpload,
  setIsSaving: (isSaving: boolean, message: string) => void,
  setAlert: (settings: MainAlertProps) => void
) => {
  const response = await fetch(`/api/settings/agency/details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getToken()}`,
    },
    body: JSON.stringify(uploadData),
  });

  if (!response.ok) {
    setIsSaving(false, "");
    setAlert({
      status: "error",
      message: "Error saving settings",
      secondsToClose: 10,
    });
    return;
  }

  const data = (await response.json()).data as AgencyData;

  setAlert({
    status: "success",
    message: "Settings saved successfully",
    secondsToClose: 4,
  });
  setIsSaving(false, "");

  return data;
};

export const FetchAgencySettings = async (
  getToken: () => Promise<string | undefined>,
  loadingMessage?: string,
  setIsLoading?: (isSaving: boolean, message: string) => void,
  agencyId?: string
): Promise<AgencyData | null> => {
  try {
    setIsLoading && setIsLoading(true, loadingMessage || "");

    const data = await apiLive<{ success: boolean; data: AgencyData }>(
      getToken,
      agencyId
        ? `/api/admin/agency/${agencyId}/details`
        : "/api/settings/agency/details"
    );

    setIsLoading && setIsLoading(false, "");

    if (!data || !data.success) {
      return null;
    }

    return data.data;
  } catch (error) {
    setIsLoading && setIsLoading(false, "");
    return null;
  }
};

export const FetchAgencyIntegrations = async (
  getToken: () => Promise<string | undefined>,
  setIsLoading: (isSaving: boolean) => void
): Promise<AgencyConnection[] | null> => {
  try {
    setIsLoading(true);

    const data = await apiLive<AgencyConnection[]>(
      getToken,
      `/api/settings/agency/connection`
    );

    setIsLoading(false);

    return data;
  } catch (error) {
    setIsLoading(false);
    return null;
  }
};

export const HandleOpenBillingSession = async (
  getToken: () => Promise<string | undefined>,
  setAlert: (settings: MainAlertProps) => void
) => {
  const session = await apiLive(getToken, "/api/billing/session");

  if (!session) {
    setAlert({
      status: "error",
      message: "Failed to open billing, please contact support",
      secondsToClose: 10,
    });
    return;
  }

  window.open(session.url, "_blank");
};
